import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import axiosInstance from 'src/utils/axios';

const slice = createSlice({
  name: 'studentSearch',

  initialState: {
    loadingStudentSearchs: false,

    StudentSearchFilter: {
      page: 1,
      limit: 10,
      totalPage: 1,
    },

    StudentSearchs: [],

    filter: {
      select_school_type: false,
      select_grade: false,
    },
    fieldSearch: {
      year: true,
      schoolType: true,
      level: true,
      class: true,
      idCamemis: true,
      id: true,
      lastname: true,
      firstname: true,
      lastname_firstname: true,
      gender: true,
      user: true,
      not_class: true,
    },
    // fieldSearch: [
    //   {
    //       name : "ឆ្នាំសិក្សា",
    //       name_en : "Yearly",
    //       fieldname: 'year',
    //       display : true,
    //       type: 'select',
    //       onChange: null,
    //       data: [],
    //   },
    //   {
    //       name : "ជ្រើសរើសភូមិសិក្សា",
    //       name_en : "ជ្រើសរើសភូមិសិក្សា",
    //       fieldname: 'school_type',
    //       display : true,
    //       type: 'select',
    //       onChange: '',
    //       data: [],
    //   },
    //   // {
    //   //     name : "កម្រិត",
    //   //     name_en : "level",
    //   //     fieldname: 'level',
    //   //     display : true,
    //   //     type: 'select',
    //   //     onChange: null,
    //   //     data: [],
    //   // },
    //   // {
    //   //     name : "ថ្នាក់ទី",
    //   //     name_en : "class",
    //   //     fieldname: 'class',
    //   //     display : true,
    //   //     type: 'select',
    //   //     onChange: null,
    //   //     data: [],
    //   // },
    //   // {
    //   //     name : "អត្តលេខ CAMEMIS",
    //   //     name_en : "ID CAMEMIS",
    //   //     fieldname: 'id_camemis',
    //   //     display : true,
    //   //     type: 'text',
    //   //     onChange: null,
    //   //     data: [],
    //   // },
    //   // {
    //   //     name : "អត្តលេខ",
    //   //     name_en : "ID",
    //   //     fieldname: 'id',
    //   //     display : true,
    //   //     type: 'text',
    //   //     onChange: null,
    //   //     data: [],
    //   // },
    //   // {
    //   //     name : "នាមត្រកូល",
    //   //     name_en : "Lastname",
    //   //     fieldname: 'lastname',
    //   //     display : true,
    //   //     type: 'text',
    //   //     onChange: null,
    //   //     data: [],
    //   // },
    //   // {
    //   //     name : "នាមខ្លួន",
    //   //     name_en : "Firstname",
    //   //     fieldname: 'firstname',
    //   //     display : true,
    //   //     type: 'text',
    //   //     onChange: null,
    //   //     data: [],
    //   // },
    //   // {
    //   //     name : "គោត្តនាម នាម",
    //   //     name_en : "Lastname Firstname",
    //   //     fieldname: 'lastname_firstname',
    //   //     display : true,
    //   //     type: 'text',
    //   //     onChange: null,
    //   //     data: [],
    //   // },
    //   // {
    //   //     name : "ភេទ",
    //   //     name_en : "gender",
    //   //     fieldname: 'gender',
    //   //     display : true,
    //   //     type: 'select',
    //   //     onChange: null,
    //   //     data: [],
    //   // },
    //   // {
    //   //     name : "ខ្ញុំប្រើប្រាស់សេវាកម្ម CAMEMIS App",
    //   //     name_en : "user",
    //   //     fieldname: 'user',
    //   //     display : true,
    //   //     type: 'select',
    //   //     onChange: null,
    //   //     data: [],
    //   // },
    //   // {
    //   //     name : "មិនទាន់ចុះឈ្មោះចូលក្នុងថ្នាក់",
    //   //     name_en : "not have in class",
    //   //     fieldname: 'not_class',
    //   //     display : true,
    //   //     type: 'select',
    //   //     onChange: null,
    //   //     data: [],
    //   // },
    // ],
    schoolStructureData: [],
    academicyears: [],
    schoolTypes: [],
    listSchoolTypes: [],
    levels: [],
    grades: [],
    filterSearch: {
      year: null,
      schoolType: null,
      level: null,
      grade: null,
    },
  },

  reducers: {
    setLoadingStudentSearchs: (state, action) => {
      state.loadingStudentSearchs = action.payload;
    },
    // setStudentSearchsFeild: (state, action) => {
    //   state.fieldSearch = action.payload;
    // },
    setStudentSearchs: (state, action) => {
      state.StudentSearchs = action.payload;
    },

    // =======>> CheckBoxFieldSearchSutdent
    checkBoxFieldSearchsYear: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.year = check;
    },
    checkBoxFieldSearchsTypeSchool: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.schoolType = check;
    },
    checkBoxFieldSearchsLevel: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.level = check;
    },
    checkBoxFieldSearchsClass: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.class = check;
    },
    checkBoxFieldSearchsIdCamemis: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.idCamemis = check;
    },
    checkBoxFieldSearchsID: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.id = check;
    },
    checkBoxFieldSearchsLastname: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.lastname = check;
    },
    checkBoxFieldSearchsFirstname: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.firstname = check;
    },
    checkBoxFieldSearchsLast_Firstname: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.lastname_firstname = check;
    },
    checkBoxFieldSearchsGender: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.gender = check;
    },
    checkBoxFieldSearchsUser: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.user = check;
    },
    checkBoxFieldSearchsNotClass: (state, action) => {
      const { name, check } = action.payload;
      state.fieldSearch.not_class = check;
    },

    checkBoxTableStudent: (state, action) => {
      const { name, check } = action.payload;
      const item = state.StudentSearchs.find((selectedField) => selectedField.name === name);
      item.display = check;
    },

    filterData: (state, action) => {
      state.filter = action.payload;
    },

    setSearch(state, action) {
      state.filterSearch = action.payload;
    },
    setSchoolStructureData: (state, action) => {
      state.schoolStructureData = action.payload;
    },
    setSchoolTypes: (state, action) => {
      state.schoolTypes = action.payload;
    },
    setListSchoolTypes: (state, action) => {
      state.listSchoolTypes = action.payload;
    },
    setLevel: (state, action) => {
      state.levels = action.payload;
    },
    setClass: (state, action) => {
      state.grades = action.payload;
    },
  },
});

export function setFilterSearch(params) {
  return async (dispatch) => {
    dispatch(StudentSearchActions.setSearch(params));
  };
}

export const setFilter = () => async (dispatch) => {
  try {
    const res = await dispatch(StudentSearchActions.filterData());
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const HeaderStudentFieldSearch = () => async (dispatch) => {
  try {
    dispatch(StudentSearchActions.setLoadingStudentSearchs(true));
    const res = await dispatch(StudentSearchActions.setStudentSearchsFeild());
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const HeaderStudentSearchs = () => async (dispatch) => {
  try {
    dispatch(StudentSearchActions.setLoadingStudentSearchs(true));
    const res = await axiosInstance.get(`/student/get-header-search-student`);
    if (res?.data?.data) {
      dispatch(StudentSearchActions.setStudentSearchs(res?.data?.data));
    }
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const ListSchoolTypes = () => async (dispatch) => {
  try {
    dispatch(StudentSearchActions.setLoadingStudentSearchs(true));
    const res = await axiosInstance.get(`/staff-information/get-staff-search-categories`);
    if (res?.data?.data) {
      dispatch(StudentSearchActions.setListSchoolTypes(res?.data?.data));
    }
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const SchoolStructureData = (param) => async (dispatch) => {
  try {
    dispatch(StudentSearchActions.setLoadingStudentSearchs(true));
    const res = await axiosInstance.post(`/student/get-school-structure`, param);
    if (res?.data?.data) {
      dispatch(StudentSearchActions.setSchoolStructureData(res?.data?.data));
    }
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const StudentSearchPromiseActions = {
  setFilterSearch,
  setFilter,
  HeaderStudentSearchs,
  HeaderStudentFieldSearch,
  SchoolStructureData,
  ListSchoolTypes,
};
export const StudentSearchActions = slice.actions;
export const StudentSearchSelector = () => useSelector((state) => state.studentSearch);
export default slice.reducer;
