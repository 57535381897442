const en = {
  TOOLTIP: {
    COMING_SOON: 'Coming Soon...',
  },

  SNACKBAR: {
    SAVED: 'Saved',
    SAVING: 'Saving...',
    SUCCESSFUL: 'Successful',
    SURE_TO_LEAVE: 'Are you sure you want to leave',
    SURE_TO_DELETE: 'Are you sure you want to delete',
    DUPLICATE_SUCCESSFULLY: 'Duplicated successfully to',
    START_BEFORE_END_DATE: 'End date must be later than start date',
    STATUS: 'Upload Progress',
  },

  AUTOCOMPLETE: {
    ACADEMIC_YEAR: 'Academic Year',
    EDUCATION_SYSTEM: 'Education System',
    GRADE: 'Grade',
    CLASS: 'Class',
    FULLNAME_IN_LATIN: 'Fullname (LATIN)',
    FULLNAME_IN_KHMER: 'Fullname (KHMER)',
    STAFF_CODE: 'Staff Code',
    START_DATE: 'Start Data',
    END_DATE: 'End Date',
  },

  BUTTON: {
    ADD: 'Add',
    ALL: 'All',
    EDIT: 'Edit',
    DONE: 'Done',
    NEXT: 'Next',
    BACK: 'Back',
    SEND: 'Send',
    SKIP: 'Skip',
    SAVE: 'Save',
    LOGIN: 'Login',
    TOPIC: 'Topic',
    DRAFT: 'Draft',
    RESET: 'Reset',
    APPLY: 'Apply',
    CLEAR: 'Clear',
    SHARE: 'Share',
    LEAVE: 'Leave',
    RETURN: 'Return',
    IMPORT: 'Import',
    RENAME: 'Rename',
    REMOVE: 'Remove',
    CREATE: 'Create',
    DELETE: 'Delete',
    CANCEL: 'Cancel',
    EXPORT: 'Export',
    MANAGE: 'Manage',
    ASSIGN: 'Assign',
    REVIEW: 'Review',
    PRESET: 'Preset',
    ADD_TAG: 'Add Tag',
    CONNECT: 'Connect',
    SHUFFLE: 'Shuffle',
    PREVIEW: 'Preview',
    CONFIRM: 'Confirm',
    PUBLISH: 'Publish',
    DISCARD: 'Discard',
    SETTING: 'Settings',
    SCHEDULE: 'Schedule',
    DOWNLOAD: 'Download',
    CONTINUE: 'Continue',
    ANALYZING: 'Analyzing',
    DUPLICATE: 'Duplicate',
    LEAVE_VIEW: 'Leave View',
    JOIN_CLASS: 'Join Class',
    SEND_ADMIN: 'Send Admin',
    LEAVE_QUIZ: 'Leave Quiz',
    SAVE_DRAFT: 'Save Draft',
    DISCONNECT: 'Disconnected',
    PUBLISH_NOW: 'Publish Now',
    SUBMIT_QUIZ: 'Submit Quiz',
    ASSIGN_TASK: 'Assign Task',
    GET_STARTED: 'Get Started',
    SAVE_CHANGE: 'Save Changes',
    GO_TO_COURSE: 'Go to Course',
    LEAVE_COURSE: 'Leave Course',
    UPDATE_PRESET: 'Update Preset',
    DELETE_COURSE: 'Delete Course',
    PLAY_RECORDING: 'Play Recordings',
    REVERT_TO_DRAFT: 'Revert to Draft',
    ADD_DESCRIPTION: 'Add Description',
    UPDATE_ATTENDANCE: 'Update Attendance',
    CONFIRM_CONTINUE: 'Confirm and Continue',
    START_WITH_ADD: 'Start new one with add button',
    SEARCH: 'Search',
    DOWNLOAD_EXCEL_TEMPALTE: 'Download Excel Tamplate',
    IMPORT_EXCEL_FILE: 'Import Excel File',
    UPLOAD: 'Upload',
  },

  LOGIN_PAGE: {
    PASSWORD: 'Password',
    BACKGROUND: 'Background',
    USERNAME: 'Username or Email',
    SELECT_SCHOOL: 'Select School',
    REMEMBER_SCHOOL: 'Remember School',
    FORGOT_PASSWORD: 'Forgot Password?',
    YOUR_SCHOOL: 'Please, select your school',
    LOGIN_TO_ACCESS: 'Login to access the Camis Management System',
  },

  STUDENT_INFO_STATUS: {
    LEVEL_UP_TAB: 'Level Up',
    RE_EXAM_LIST_TAB: 'Re-Exam List',
    STUDENT_STATUS: 'Student Status',
    STUDENT_INFO: 'Student Information',
    DROPOUT_STUDENT_TAB: 'Dropout Student',
    LIST_STUDENT_FAIL_TAB: 'List Student Fail',
    LIST_STUDENT_PASS_TAB: 'List Student Pass',
  },

  TABLE_HEADER: {
    STATUS: 'Status',
    EMPLOYEE_ID: 'Employee ID',
    LASTNAME: 'Last Name',
    FIRSTNAME: 'First Name',
    CAMEMIS_USER_ROLE: 'Camemis User Role',
    GENDER: 'Gender',
    DOB: 'Date of Birth',
    ADDRESS: 'Address',
    CONTACT_PHONE_NUMBER: 'Phone Number',
    CONTACT_EMAIL: 'Email',
    POSITION: 'Position',
    DEGREE: 'Degree',
    SPECIALTY: 'Specialty',
    FRAMWORK: 'Framwork',
    PROBATION_DATE: 'Probation Date',
    EMPLOYED_DATE: 'Employed Date',
    LAST_STATUS: 'Last Status',
    LAST_STATUS_DATE: 'Last Status Date',
    PROKAS: 'Prokas',
    RETIRED_DATE: 'Retired Date',
  },

  SYSTEM_USER: {
    GENERAL_TAB: 'General',
    SYSTEM_USER: 'System User',
    USER_SETTING: 'User Setting',
    INFORMATION_TAB: 'Contact Information',
    CHANGE_PASSWORD_TAB: 'Change Password',

    GENERAL: {
      GENDER: 'Gender',
      NAME: 'Nickname',
      ALLOWED: 'Allowed',
      REID_ID: 'REID ID',
      OWN_CODE: 'Staff ID',
      LASTNAME: 'Lastname',
      DOB: 'Date of Birth',
      RELIGION: 'Religion',
      FIRSTNAME: 'Firstname',
      ETHNICITY: 'Ethnicity',
      MAX_SIZE: 'Mix size of',
      NATONALITY: 'Nationality',
      CAMEMIS_CODE: 'CAMEMIS Code',
      UPDATE_PHOTO: 'Update Photo',
      UPLOAD_PHOTO: 'Upload Photo',
      LASTNAME_LATIN: 'Lastname (Latin)',
      FIRSTNAME_LATIN: 'Firstname (Latin)',
    },

    INFORMATION: {
      ADDRESS: 'Address',
      CONTACT_EMAIL: 'Contact Email',
      CONTACT_PHONE_NUMBER: 'Contact Phone Number',
    },

    CHANGE_PASSWORD: {
      PASSWORD: 'Password',
      CURRENT_PASSWORD: 'Current Password',
      PASSWORD_COMFIRMATION: 'Password Confirmation',
    },
  },

  REGISTER_NEW_STUDENT: {
    LEVEL: 'Please Select School',
    CONFIRM_LEVEL: 'Please Select Your School',
  },
};

export default en;
