// @mui
import { useEffect, useState } from 'react';
import { Box, Grid, Paper, styled } from '@mui/material';
import { isEmpty } from 'lodash';

// =============================
import { useParams } from 'react-router';
import {
  RegisterNewStudentSelector,
  StudentPromiseActions,
} from 'src/redux/slices/registerNewStudent';
import { dispatch } from 'src/redux/store';
import EmptyContent from 'src/components/empty-content';
import { LoadingScreen } from 'src/components/loading-screen';
import FormRegisterNewStudent from './FormRegister';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const school = [
  { id: '203', label: 'School Test' },
  { id: '250', label: 'School Samaki' },
  { id: '240', label: 'School Tul Tom pung' },
];

export default function RegisterNewStudent() {
  const { schoolId } = useParams();
  const [loading, setLoading] = useState(true);
  const { listSchool } = RegisterNewStudentSelector();

  const getSchool = async (params) => {
    setLoading(true);
    let res = [];
    try {
      res = await dispatch(StudentPromiseActions.getSchoolDataSuccess(params));
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    return res;
  };

  const NameSchool = !isEmpty(listSchool)
    ? listSchool.find((item) => item?.id === parseInt(schoolId, 10))
    : [];
  // Use Effect
  useEffect(() => {
    if (schoolId) {
      getSchool(schoolId);
    }
  }, [schoolId]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        {loading ? (
          <Box sx={{ height: '500px' }}>
            <LoadingScreen />
          </Box>
        ) : isEmpty(NameSchool) ? (
          <Item sx={{ height: '500px' }}>
            <EmptyContent title="ទិន្នន័យសាលាមិនត្រឹមត្រូវ" />
          </Item>
        ) : (
          <Item>
            <FormRegisterNewStudent NameSchool={NameSchool} />
          </Item>
        )}
      </Grid>
    </Grid>
  );
}
