import { useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { PATH_AFTER_LOGIN } from 'src/config-global';
import { useLocales } from 'src/locales';
import RegisterNewStudent from 'src/pages/dashboard/register-new-student';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

export default function Router() {
  const { currentLang } = useLocales();

  useEffect(() => {
    document.documentElement.lang = currentLang?.value;
  }, [currentLang]);

  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // {
    //   path: 'student/register/:schoolId',
    //   element: <RegisterNewStudent />,
    // },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
