import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import axiosInstance from 'src/utils/axios';

const slice = createSlice({
  name: 'staffinfo',
  initialState: {
    teachers: [],
    headerSetting: [],
    loadingStaff: false,
    staffGender: [],
    staffRole: [],
    yearly: [],
    staffSchoolStructure: [],
    headerTable: [],
    staffData: [],
    schoolType: [],
  },
  reducers: {
    setStartLoadingStaff: (state) => {
      state.loadingStaff = true;
    },
    setStopLoadingStaff: (state) => {
      state.loadingStaff = false;
    },
    setHeaderSetting: (state, action) => {
      state.headerSetting = action.payload;
      localStorage.setItem('headerSettings', JSON.stringify(state.headerSetting));
      // console.log('state', JSON.parse(localStorage.getItem('headerSettings')));
    },
    setStaffGender: (state, action) => {
      state.staffGender = action.payload;
    },
    setStaffRole: (state, action) => {
      state.staffRole = action.payload;
    },
    setSchoolType: (state, action) => {
      state.schoolType = action.payload;
    },
    setStaffSchoolStructure: (state, action) => {
      state.staffSchoolStructure = action.payload;
    },
    setStaffHeaderTable: (state, action) => {
      state.headerTable = action.payload;
      localStorage.setItem('headerTables', JSON.stringify(action.payload));
    },
    setAllDataStaff: (state, action) => {
      state.staffData = action.payload;
    },
  },
});

const staffSearchHeaderSetting = () => async (dispatch) => {
  try {
    dispatch(StaffActions.setStartLoadingStaff());
    const res = await axiosInstance.get(`/staff-information/get-staff-search-header-setting`);
    dispatch(StaffActions.setHeaderSetting(res?.data?.data));
  } catch (error) {
    console.log('error');
  }
};

const staffSearchCategories = () => async (dispatch) => {
  try {
    dispatch(StaffActions.setStartLoadingStaff());
    const res = await axiosInstance.get(`/staff-information/get-staff-search-categories`);
    console.log('res', res);
    // dispatch(StaffActions.setStaffGender(res?.data?.data?.genders));
    dispatch(StaffActions.setStaffRole(res?.data?.data?.roles));
    dispatch(StaffActions.setSchoolType(res?.data?.data?.school_type));
  } catch (error) {
    console.log('error');
  }
};

const staffSchoolStructure = () => async (dispatch) => {
  try {
    dispatch(StaffActions.setStartLoadingStaff());
    const res = await axiosInstance.get(
      `/staff-information/get-staff-search-school-structure-categories`
    );
    dispatch(StaffActions.setStaffSchoolStructure(res?.data?.data));
  } catch (error) {
    console.log('error');
  }
};

const staffSaveSearchSetting = (data) => async (dispatch) => {
  console.log('data', data);
  try {
    dispatch(StaffActions.setStartLoadingStaff());
    const res = await axiosInstance.post(`/staff-information/save-list-staff-search-setting`, {
      data,
    });
    dispatch(StaffActions.setHeaderSetting(res?.data?.data));
    localStorage.setItem('headerSettings', JSON.stringify(res?.data?.data));
  } catch (error) {
    console.log('error');
  }
};

const staffFilterColumn = () => async (dispatch) => {
  try {
    dispatch(StaffActions.setStartLoadingStaff());
    const res = await axiosInstance.get(`/staff-information/get-staff-filter-column`);
    console.log('res', res);
    dispatch(StaffActions.setStaffHeaderTable(res?.data?.data));
  } catch (error) {
    console.log('error');
  }
};

const AllStaffData = () => async (dispatch) => {
  try {
    dispatch(StaffActions.setStartLoadingStaff());
    const res = await axiosInstance.post(`/staff-information/action-search-staff-v2`, {
      search_field: '',
      role: '',
      gender: '',
      education_type: '',
      school_type: '',
      schoolyear_id: '',
      grade_id: '',
      class_id: '',
    });
    // console.log('res', res);
    dispatch(StaffActions.setAllDataStaff(res?.data?.data));
    dispatch(StaffActions.setStopLoadingStaff());
  } catch (error) {
    console.log('error');
  }
};

const StaffSaveHeaderTable = (data) => async (dispatch) => {
  try {
    // dispatch(StaffActions.setStartLoadingStaff());
    const res = await axiosInstance.post(`/staff-information/save-list-staff-search-setting`, {
      data,
    });

    dispatch(StaffActions.setStaffHeaderTable(res?.data?.data));
    localStorage.setItem('headerTables', JSON.stringify(res?.data?.data));
  } catch (error) {
    console.log('error');
  }
};

const SearchStaff = (data) => async (dispatch) => {
  console.log('data', data);
  try {
    dispatch(StaffActions.setStartLoadingStaff());
    const res = await axiosInstance.post(`/staff-information/action-search-staff-v2`, {
      ...data,
    });
    dispatch(StaffActions.setAllDataStaff(res?.data?.data));
    dispatch(StaffActions.setStopLoadingStaff());
    console.log('res', res);
  } catch (error) {
    console.log('error');
  }
};

export const StaffPromiseActions = {
  staffSearchHeaderSetting,
  staffSearchCategories,
  staffSchoolStructure,
  staffFilterColumn,
  staffSaveSearchSetting,
  AllStaffData,
  StaffSaveHeaderTable,
  SearchStaff,
};
export const StaffActions = slice.actions;
export const StaffSelector = () => useSelector((state) => state.staffinfo);
export default slice.reducer;
