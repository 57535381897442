import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const slice = createSlice({
  name: 'teacher',
  initialState: {
    teachers: [],
    loadingTeachers: false,
    teacherFilter: {
      page: 1,
      limit: 10,
      totalPage: 1,
    },
    selectedTeacher: null,
    histories: [],
    loadingHistories: false,
    selectedHistory: null,
    historyFilter: {
      page: 1,
      limit: 10,
      totalPage: 1,
    },
    openEvaluationDialog: null,
    selectedEvaluation: null,
  },
  reducers: {
    setTeachers: (state, action) => {
      state.teachers = action.payload;
    },
    setLoadingTeachers: (state, action) => {
      state.loadingTeachers = action.payload;
    },
    setTeacherFilter: (state, action) => {
      state.teacherFilter = action.payload;
    },
    setSelectedTeacher: (state, action) => {
      state.selectedTeacher = action.payload;
    },
    setHistories: (state, action) => {
      state.histories = action.payload;
    },
    setLoadingHistories: (state, action) => {
      state.loadingHistories = action.payload;
    },
    setSelectedHistory: (state, action) => {
      state.selectedHistory = action.payload;
    },
    setHistoryFilter: (state, action) => {
      state.historyFilter = action.payload;
    },
    setLoadingEvaluation: (state, action) => {
      state.loadingEvaluation = action.payload;
    },
    setOpenEvaluationDialog: (state, action) => {
      state.openEvaluationDialog = action.payload;
    },
    setSelectedEvaluation: (state, action) => {
      state.selectedEvaluation = action.payload;
    },
  },
});

const fetchTeachers = () => async (dispatch) => {
  try {
    dispatch(TeacherActions.setLoadingTeachers(true));

    const res = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: Array.from({ length: 10 }).map((_, index) => ({
            id: index + 1,
            name: `Teacher ${index + 1}`,
            email: `teacher${index + 1}@gmail.com`,
            phone: `012345678${index + 1}`,
            address: `Address ${index + 1}`,
            subject: `Subject ${index + 1}`,
          })),
        });
      }, 1000);
    });

    dispatch(TeacherActions.setTeachers(res?.data));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(TeacherActions.setLoadingTeachers(false));
  }
};

const fetchTeacherHistory = (teacherId) => async (dispatch) => {
  try {
    dispatch(TeacherActions.setLoadingHistories(true));

    const res = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: Array.from({ length: 9 }).map((_, index) => ({
            id: index + 1,
            date: `2021-09-0${index + 1}`,
            score: 10,
            teacherId,
          })),
        });
      }, 1000);
    });

    dispatch(TeacherActions.setHistories(res?.data));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(TeacherActions.setLoadingHistories(false));
  }
};

const fetchEvaluation = (evaluationId) => async (dispatch) => {
  try {
    dispatch(TeacherActions.setLoadingEvaluation(true));

    const res = await new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: {},
        });
      }, 1000);
    });

    dispatch(TeacherActions.setSelectedEvaluation(res?.data));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(TeacherActions.setLoadingEvaluation(false));
  }
};

const saveEvaluation =
  ({ data }) =>
  async (dispatch) => {
    try {
      dispatch(TeacherActions.setLoadingEvaluation(true));

      await new Promise((r) => setTimeout(r, 1000));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(TeacherActions.setLoadingEvaluation(false));
    }
  };

export const TeacherPromiseActions = {
  fetchTeachers,
  fetchTeacherHistory,
  fetchEvaluation,
  saveEvaluation,
};
export const TeacherActions = slice.actions;
export const TeacherSelector = () => useSelector((state) => state.teacher);
export default slice.reducer;
