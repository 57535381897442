import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { useSelector } from 'react-redux';

const axiosInstance = 'http://192.168.0.53:8000/api/v1/admin/';
const slice = createSlice({
  name: 'registerNewStudent',
  initialState: {
    listSchool: [],
    loading: false,
  },
  reducers: {
    getListSchool: (state, action) => {
      state.listSchool = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export function getSchoolDataSuccess() {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `https://api-services.camemis-learn.com/api/v1/admin/list-customer-school`
      );
      if (res?.status === 200) {
        dispatch(StudentActions.getListSchool(res?.data?.data));
      }
      return res;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
}

export const StudentPromiseActions = {
  getSchoolDataSuccess,
};
export const StudentActions = slice.actions;
export const RegisterNewStudentSelector = () => useSelector((state) => state.registerNewStudent);
export default slice.reducer;
