// @mui
import { enUS, khKH } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    labelKh: 'អង់គ្លេស',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Khmer',
    labelKh: 'ខ្មែរ',
    value: 'kh',
    systemValue: khKH,
    icon: '/assets/icons/flags/ic_flag_kh.svg',
  },
];

export const defaultLang = allLangs[0]; // English
