import { EuroTwoTone } from '@mui/icons-material';
import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import axiosInstance from 'src/utils/axios';

const slice = createSlice({
  name: 'studentmigration',
  initialState: {
    studentHeader: [],
    tableData: [],
    loading: false,
    schoolStructure: [],
  },
  reducers: {
    getDataSuccess: (state, action) => {
      state.studentHeader = action.payload;
    },
    editStudentData: (state, action) => {
      const { full, check } = action.payload;
      const item = state.studentHeader.find((selectedField) => selectedField.name === full);
      item.display = check;
    },
    getTableDataSuccess: (state, action) => {
      state.tableData = action.payload;
    },
    editTableDate: (state, action) => {
      const { full, check } = action.payload;
      const item = state.tableData.find((selectedField) => selectedField.name === full);
      item.display = check;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    exportExcelSuccess: (state, action) => {
      state.loading = false;
      state.studentHeader = action.payload;
    },
    importExcelSuccess: (state, action) => {
      state.loading = false;
    },
    getSchoolStructureSuccess: (state, action) => {
      state.schoolStructure = action.payload;
    },
    resetTableDataSuccess(state) {
      state.tableData = [];
    },
  },
});

export function resetTableData() {
  return async (dispatch) => {
    dispatch(slice.actions.resetTableDataSuccess());
  };
}

export const getStudentHeaderData = () => async (dispatch) => {
  try {
    dispatch(StudentMigrationAction.setLoading(true));

    const response = await axiosInstance.get(`/student-temp/student-field-setting`);

    if (response?.status === 200) {
      dispatch(StudentMigrationAction.getDataSuccess(response?.data?.data));
    }
    // console.log(response);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    dispatch(StudentMigrationAction.setLoading(false));
  }
};

// export const exportExcelFile = (params) => async (dispatch) => {
//   try {
//     dispatch(StudentMigrationAction.setLoading(true));
//     const response = await axiosInstance.post('/student-temp/get-list-student-temp', params);

//     if (response?.status === 200) {
//       dispatch(StudentMigrationAction.exportExcelSuccess(response?.data?.data));
//     }
//     // console.log(response);
//     return response;
//   } catch (error) {
//     console.error(error);
//     return error;
//   } finally {
//     dispatch(StudentMigrationAction.setLoading(false));
//   }
// };

export const getSchoolStructure = (params) => async (dispatch) => {
  try {
    dispatch(StudentMigrationAction.setLoading(true));
    const response = await axiosInstance.post(`/student/get-school-structure`, params);

    if (response?.status === 200) {
      dispatch(StudentMigrationAction.getSchoolStructureSuccess(response?.data?.data));
    }
    // console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    dispatch(StudentMigrationAction.setLoading(false));
  }
};

export const importExcelFile = (params) => async (dispatch) => {
  try {
    dispatch(StudentMigrationAction.setLoading(true));
    const respone = await axiosInstance.post(`/student-temp/import-student-template`, params);
    console.log(respone);
    return respone;
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    dispatch(StudentMigrationAction.setLoading(false));
  }
};

export const downloadExcelTemplate = (params) => async (dispatch) => {
  try {
    dispatch(StudentMigrationAction.setLoading(true));

    const response = await axiosInstance.post('student-temp/export-student-template', params, {
      responseType: 'arraybuffer',
    });

    const type = response.headers['Content-Type'];

    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type,
      });

      const link = document.createElement('a');

      // Handle Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+?)"/);
      const suggestedFilename = filenameMatch
        ? filenameMatch[1]
        : 'student_migration_template.xlsx';
      link.download = suggestedFilename;

      link.href = window.URL.createObjectURL(blob);
      link.click();
    }

    return response;
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    dispatch(StudentMigrationAction.setLoading(false));
  }
};

export const getImportedData = (params) => async (dispatch) => {
  try {
    dispatch(StudentMigrationAction.setLoading(true));
    const response = await axiosInstance.post(`/student-temp/get-list-student-temp`, params);

    if (response?.status === 200) {
      dispatch(StudentMigrationAction.getTableDataSuccess(response?.data?.data));
    }
    console.log(response);
    return response;
  } catch (error) {
    console.error(error);
    return error;
  } finally {
    dispatch(StudentMigrationAction.setLoading(false));
  }
};

export const updateStudentData = (params) => async (dispatch) => {
  try {
    dispatch(StudentMigrationAction.setLoading(true));
    const response = await axiosInstance.post(`/student-temp/update-student-temp`, params);

    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    dispatch(StudentMigrationAction.setLoading(false));
  }
};

export const StudentMigrationSelector = () => useSelector((state) => state.studentmigration);
export const StudentMigrationAction = slice.actions;
export default slice.reducer;

// export function getQRCodeData(param, param2) {
//   return async (dispatch) => {
//     try {
//       const token = localStorage.getItem('accessToken');
//       // const accessToken = token !== null || token !== undefined ? JSON.parse(token) : {};
//       const accessToken =
//         'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGktc2VydmljZXMuY2FtZW1pcy1sZWFybi5jb21cL2FwaVwvdjFcL2FkbWluXC9sb2dpbiIsImlhdCI6MTY5NDY4MDYwOCwiZXhwIjoxNzI2MjE2NjA4LCJuYmYiOjE2OTQ2ODA2MDgsImp0aSI6Ik1oVVV2OUpRQWZSaVhKREEiLCJzdWIiOjEsInBydiI6IjVhMTkxZjgyNDJjZDhjZDBjNzJlMWE3ZmEzZTczMjNlNjVhZWJkOTkifQ.EOifY8nIYsGhGvRF7RBon23eEEeq_Ada61m87eE38C0';
//       // console.log('accessToken', accessToken);
//       const headers = {
//         APPKEY: 'L2tPsd3PJ26RQwuaQvNVyhabWqVcdE',
//         'Content-Type': 'application/json',
//         Accept: 'application/json',
//         Authorization: `Bearer ${accessToken}`,
//       };
//       const response = await axiosInstance({
//         url: `qr-code-staff?school_id=${param}&schoolyear_id=${param2}`,
//         method: 'GET',
//         headers,
//         responseType: 'blob',
//       });
//       const type = response.headers['Content-Type'];
//       const blob = new Blob([response.data], {
//         type,
//         //   type: type,
//         encoding: 'UTF-8',
//       });
//       if (response.status === 200) {
//         const link = document.createElement('a');
//         link.href = window.URL.createObjectURL(blob);
//         link.download = 'teacher_qr_code.pdf';
//         link.click();
//       }
//       return response;
//     } catch (error) {
//       // dispatch(hasError(error));
//       return error;
//     }
//   };
// }
