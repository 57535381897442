import { useMemo } from 'react';
// components
import Iconify from 'src/components/iconify';
import { useAccessModule } from 'src/hooks/use-access-module';
import { useLocales } from 'src/locales';
// routes
import { paths } from 'src/routes/paths';
import { generateStructure } from './gen';

// ----------------------------------------------------------------------

const ICONS = {
  campus_migration: <Iconify icon="carbon:migrate" />,
  student_information: <Iconify icon="ph:student-duotone" />,
  staff_information: <Iconify icon="heroicons:users" />,
  report_and_statistics: <Iconify icon="mdi:report-box-outline" />,
  campus_setting: <Iconify icon="tdesign:task-setting" />,
  general_setting: <Iconify icon="ant-design:setting-twotone" />,
  system_user: <Iconify icon="heroicons:user" />,
  teacher: <Iconify icon="mdi:teacher" />,
  student: <Iconify icon="mdi:register" />,
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { currentLang } = useLocales();
  const { access_modules } = useAccessModule();
  const school = localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null;

  const data = useMemo(
    () => [
      {
        // subheader: currentLang.value === 'en' ? school.school_name_en : school.school_name,
        items: [
          {
            title: 'Campus migration',
            path: paths.dashboard.campus_migration.root,
            icon: ICONS.campus_migration,
            children: [
              {
                title: 'Student Migration',
                path: paths.dashboard.campus_migration.student_migration,
              },
              {
                title: 'Staff Migration',
                path: paths.dashboard.campus_migration.staff_migration,
              },
            ],
          },
          {
            title: 'Student Information',
            path: paths.dashboard.student_information.root,
            icon: ICONS.student_information,
            children: [
              {
                title: 'Student Search',
                path: paths.dashboard.student_information.search,
              },
              {
                title: 'Guardian Search',
                path: paths.dashboard.student_information.guardian,
              },
              {
                title: 'Learning Result',
                path: paths.dashboard.student_information.result,
              },
            ],
          },
          {
            title: 'Staff Information',
            path: paths.dashboard.staff_information.root,
            icon: ICONS.staff_information,
            children: [
              {
                title: 'Staff Info',
                path: paths.dashboard.staff_information.info,
              },
              {
                title: 'Staff Status',
                path: paths.dashboard.staff_information.status,
              },
              {
                title: 'Staff Performance',
                path: paths.dashboard.staff_information.performance,
              },
              {
                title: 'Staff Attendance',
                path: paths.dashboard.staff_information.attendance,
              },
            ],
          },
          {
            title: 'Report and Statistic',
            path: paths.dashboard.report_and_statistics.root,
            icon: ICONS.report_and_statistics,
            children: [
              {
                title: 'Student',
                path: paths.dashboard.report_and_statistics.student.root,
                // children: [
                //   {
                //     title: 'Student Info',
                //     path: paths.dashboard.report_and_statistics.student.info,
                //   },
                //   {
                //     title: 'Student Status',
                //     path: paths.dashboard.report_and_statistics.student.status,
                //   },
                // ],
              },
              {
                title: 'Staff',
                path: paths.dashboard.report_and_statistics.staff.root,
                children: [
                  {
                    title: 'Staff Info',
                    path: paths.dashboard.report_and_statistics.staff.info,
                  },
                  {
                    title: 'Staff Status',
                    path: paths.dashboard.report_and_statistics.staff.status,
                  },
                ],
              },
            ],
          },
          {
            title: 'Campus Settings',
            path: paths.dashboard.campus_setting.root,
            icon: ICONS.campus_setting,
            children: [
              generateStructure(
                'High School',
                paths.dashboard.campus_setting.high_school.root,
                ['2023-2024', '2022-2023'],
                [12, 11, 10],
                ['A', 'B', 'C']
              ),
              generateStructure(
                'Secondary School',
                paths.dashboard.campus_setting.secondary_school.root,
                ['2023-2024', '2022-2023'],
                [9, 8, 7],
                ['A', 'B', 'C']
              ),
              generateStructure(
                'Primary School',
                paths.dashboard.campus_setting.primary_school.root,
                ['2023-2024', '2022-2023'],
                [6, 5, 4, 3, 2, 1],
                ['ក', 'ខ', 'គ']
              ),
              generateStructure(
                'Kindergarten',
                paths.dashboard.campus_setting.kindergarten.root,
                ['2023-2024', '2022-2023'],
                [4, 3, 2, 1]
              ),
            ],
          },
          {
            title: 'General Settings',
            path: paths.dashboard.general_setting.root,
            icon: ICONS.general_setting,
            children: [
              { title: 'Education System', path: paths.dashboard.general_setting.education_system },
              {
                title: 'Subjects',
                path: paths.dashboard.general_setting.subject.root,
                children: [
                  {
                    title: 'High School',
                    path: paths.dashboard.general_setting.subject.high_school,
                  },
                  {
                    title: 'Secondary School',
                    path: paths.dashboard.general_setting.subject.secondary_school,
                  },
                  {
                    title: 'Primary School',
                    path: paths.dashboard.general_setting.subject.primary_school,
                  },
                  {
                    title: 'Kindergarten',
                    path: paths.dashboard.campus_setting.kindergarten.root,
                  },
                ],
              },
              { title: 'Assignments', path: paths.dashboard.general_setting.assignment },
              {
                title: 'Grading System',
                path: paths.dashboard.general_setting.grading_system,
                icon: '',
              },
              {
                title: 'Academic Year',
                path: paths.dashboard.general_setting.academic_year.root,
                icon: '',
                children: [
                  {
                    title: 'Term',
                    path: paths.dashboard.general_setting.academic_year.term,
                    icon: '',
                  },
                ],
              },
              { title: 'Branches', path: paths.dashboard.general_setting.branches },
            ],
          },
          { title: 'System User', path: paths.dashboard.system_user, icon: ICONS.system_user },
          // { title: 'Teacher', path: paths.dashboard.teacher, icon: ICONS.teacher },
          // { title: 'Table Staff', path: paths.dashboard.staff, icon: ICONS.teacher },
          // { title: 'Register Student', path: paths.dashboard.student, icon: ICONS.student },
        ],
      },
      {
        subheader: currentLang.value === 'en' ? school.school_name_en : school.school_name,
        items: access_modules || [],
      },
    ],
    [access_modules, currentLang, school]
  );

  return data || [];
}
