import { m } from 'framer-motion';
import { useCallback } from 'react';
// @mui
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
// components
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import Iconify from 'src/components/iconify';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    icon: 'flagpack:gb-nir',
  },
  {
    label: 'Khmer',
    value: 'kh',
    icon: 'flagpack:kh',
  },
];

export default function LanguagePopover() {
  const popover = usePopover();
  const { currentLang, onChangeLang } = useLocales();

  const handleChangeLang = useCallback(
    (v) => {
      onChangeLang(v);
      popover.onClose();
    },
    [onChangeLang, popover]
  );

  return (
    <>
      <IconButton
        component={m?.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Iconify
          icon={allLangs.find((x) => x.value === currentLang?.value)?.icon}
          sx={{ borderRadius: 0.65, width: 28 }}
        />
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 160 }}>
        {allLangs.map((option) => (
          <MenuItem
            key={option?.value}
            selected={option?.value === currentLang?.value}
            onClick={() => handleChangeLang(option?.value)}
          >
            <Iconify icon={option?.icon} sx={{ borderRadius: 0.65, width: 28 }} />

            {option?.label}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
