import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
// @mui
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// components
import Logo from 'src/components/logo';
import { NavSectionVertical } from 'src/components/nav-section';
import Scrollbar from 'src/components/scrollbar';
import { usePathname } from 'src/routes/hook';
//
import { Autocomplete, TextField, Typography } from '@mui/material';
import { useAccessModule } from 'src/hooks/use-access-module';
import { useLocales } from 'src/locales';
import { NavToggleButton } from '../_common';
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';

// ----------------------------------------------------------------------

export default function NavVertical({ openNav, onCloseNav }) {
  const { access_modules } = useAccessModule();
  const { user } = useMockedUser();
  const school = localStorage.getItem('school') ? JSON.parse(localStorage.getItem('school')) : null;
  const { currentLang } = useLocales();

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const branches = useMemo(
    () =>
      Array.from({ length: 10 }, (_, i) => ({
        value: `branch-${i}`,
        label: `Branch ${i}`,
      })),
    []
  );

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />
      <Stack
        sx={{
          px: 2,
          my: 1,
        }}
        spacing={1}
      >
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {currentLang.value === 'en' ? school.school_name_en : school.school_name}
        </Typography>
        <Autocomplete
          options={branches}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderInput={(params) => <TextField {...params} placeholder="Select Branch" />}
          defaultValue={branches[0]}
          size="small"
        />
      </Stack>

      {access_modules === null ? (
        <Stack>
          <Skeleton variant="text" height={24} sx={{ mx: 2, mt: 2, mb: 0.5, height: '11px' }} />
          {Array.from(Array(5)).map((_, index) => (
            <Skeleton variant="rounded" height={44} sx={{ mx: 2, borderRadius: 1, my: 0.5 }} />
          ))}
        </Stack>
      ) : (
        <NavSectionVertical
          data={navData}
          config={{
            currentRole: user?.role || 'admin',
          }}
        />
      )}

      <Box sx={{ flexGrow: 1 }} />

      {/* <NavUpgrade /> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

NavVertical.propTypes = {
  onCloseNav: PropTypes.func,
  openNav: PropTypes.bool,
};
