import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const slice = createSlice({
  name: 'staffmigration',
  initialState: {
    staffHeader: [
      {
        short: '#',
        full: 'ល.រ',
        display: true,
        field: 'num',
      },

      {
        short: 'ឈ្មោះ',
        full: 'នាមខ្លួន',
        display: true,
        field: 'fullname',
      },

      {
        short: 'ថ្ងៃ ខែ ឆ្នាំ​​',
        full: 'ថ្ងៃ ខែ ឆ្នាំ​​ កំណើត',
        display: true,
        field: 'date_of_birth',
      },

      {
        short: 'ភេទ',
        full: 'ភេទ',
        display: true,
        field: 'gender',
      },
      {
        short: 'តំណែង',
        full: 'តំណែង',
        display: true,
        field: 'position',
      },

      {
        short: 'អាសយដ្ឋាន',
        full: 'អាសយដ្ឋាន',
        display: true,
        field: 'address',
      },
      {
        short: 'កាលបរិច្ឆេទផ្ទេរ',
        full: 'កាលបរិច្ឆេទផ្ទេរ',
        display: false,
        field: 'test10',
      },
      {
        short: 'កាលបរិច្ឆេទសាកល្បង',
        full: 'កាលបរិច្ឆេទសាកល្បង',
        display: true,
        field: 'PROBATION_DATE',
      },
      {
        short: 'កាលបរិច្ឆេទការងារ',
        full: 'កាលបរិច្ឆេទការងារ',
        display: true,
        field: 'EMPLOYED_DATE',
      },
      {
        short: 'កាំប្រាក់ចុងក្រោយ',
        full: 'កាំប្រាក់ចុងក្រោយ',
        display: true,
        field: 'LAST_STATUS',
      },
    ],
    tableData: [
      {
        num: '1',
        fullname: 'សុក្រ​ ចាន់',
        date_of_birth: '13/10/2001',
        gender: 1,
        status: 'active',
      },
      {
        num: '2',
        fullname: 'សៅ សុក្រ',
        date_of_birth: '13/10/2003',
        gender: 1,
        status: 'active',
      },
      {
        num: '3',
        fullname: 'អ៊ុន អ៊ុន',
        date_of_birth: '25/05/1995',
        gender: 2,
        status: 'inactive',
      },
      {
        num: '4',
        fullname: 'ប៉ាច ប៉ាច',
        date_of_birth: '02/12/1990',
        gender: 1,
        status: 'active',
      },
      {
        num: '5',
        fullname: 'សុវត្ថិ ចាន់ធី',
        date_of_birth: '18/09/1998',
        gender: 2,
        status: 'active',
      },
      {
        num: '6',
        fullname: 'ហេង ហាន',
        date_of_birth: '07/03/2000',
        gender: 1,
        status: 'inactive',
      },
      {
        num: '7',
        fullname: 'គឹម គឹម',
        date_of_birth: '11/08/1985',
        gender: 2,
        status: 'active',
      },
      {
        num: '8',
        fullname: 'គឹម ហាន',
        date_of_birth: '11/08/1995',
        gender: 2,
        status: 'active',
      },
    ],
  },
  reducers: {
    getDataSuccess: (state, action) => {
      state.staffHeader = action.payload;
    },
    editStaffData: (state, action) => {
      const { name, check } = action.payload;
      const item = state.staffHeader.find((selectedField) => selectedField.full === name);
      item.display = check;
    },
    getTableDataSuccess: (state, action) => {
      state.tableData = action.payload;
    },
    editTableRowDaat: (state, action) => {
      const { name, check } = action.payload;
      const item = state.tableData.find((selectedData) => selectedData.full === name);
      item.display = check;
    },
  },
});

export const StaffMigrationSelector = () => useSelector((state) => state.staffmigration);
export const StaffMigrationAction = slice.actions;
export default slice.reducer;
