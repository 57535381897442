import { memo, useCallback, useMemo, useState } from 'react';
// @mui
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { alpha, useTheme } from '@mui/material/styles';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { useRouter } from 'src/routes/hook';
//
import { Avatar, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { get } from 'lodash';
import Scrollbar from 'src/components/scrollbar';
import SearchNotFound from 'src/components/search-not-found';
import { useEventListener } from 'src/hooks/use-event-listener';

// ----------------------------------------------------------------------

function Searchbar() {
  const theme = useTheme();

  const router = useRouter();

  const search = useBoolean();

  const mdUp = useResponsive('up', 'md');

  const [searchType, setSearchType] = useState('students');
  const [searchQuery, setSearchQuery] = useState('');

  // const navData = useNavData();

  const handleClose = useCallback(() => {
    search.onFalse();
    setSearchQuery('');
  }, [search]);

  const handleKeyDown = (event) => {
    if (event.key === 'k' && event.metaKey) {
      search.onToggle();
      setSearchQuery('');
    }
  };

  useEventListener('keydown', handleKeyDown);

  // const handleClick = useCallback(
  //   (path) => {
  //     if (path.includes('http')) {
  //       window.open(path);
  //     } else {
  //       router.push(path);
  //     }
  //     handleClose();
  //   },
  //   [handleClose, router]
  // );

  // const handleSearch = useCallback((event) => {
  //   setSearchQuery(event.target.value);
  // }, []);

  // const dataFiltered = applyFilter({
  //   inputData: getAllItems({ data: navData }),
  //   query: searchQuery,
  // });

  // const notFound = searchQuery && !dataFiltered.length;

  // const renderItems = () => {
  //   const data = groupedData(dataFiltered);

  //   return Object.keys(data)
  //     .sort((a, b) => -b.localeCompare(a))
  //     .map((group, index) => (
  //       <List key={group || index} disablePadding>
  //         {data[group].map((item) => {
  //           const { title, path } = item;

  //           const partsTitle = parse(title, match(title, searchQuery));

  //           const partsPath = parse(path, match(path, searchQuery));

  //           return (
  //             <ResultItem
  //               path={partsPath}
  //               title={partsTitle}
  //               key={`${title}${path}`}
  //               groupLabel={searchQuery && group}
  //               onClickItem={() => handleClick(path)}
  //             />
  //           );
  //         })}
  //       </List>
  //     ));
  // };

  const renderButton = (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={search.onTrue}>
        <Iconify icon="eva:search-fill" />
      </IconButton>

      {mdUp && <Label sx={{ px: 0.75, fontSize: 12, color: 'text.secondary' }}>⌘K</Label>}
    </Stack>
  );

  const data = useMemo(
    () => ({
      students: Array.from({ length: 1000 }).map((_, index) => ({
        title: `Student ${index + 1}`,
        path: `/dashboard/student/${index + 1}`,
        code: (Math.floor(Math.random() * 100000) + 100000).toString().slice(0, 6),
      })),
      teachers: Array.from({ length: 100 }).map((_, index) => ({
        title: `Teacher ${index + 1}`,
        path: `/dashboard/teacher/${index + 1}`,
        code: (Math.floor(Math.random() * 100000) + 100000).toString().slice(0, 6),
      })),
      classes: Array.from({ length: 500 }).map((_, index) => ({
        title: `Classes ${index + 1}`,
        path: `/dashboard/classes/${index + 1}`,
        code: (Math.floor(Math.random() * 100000) + 100000).toString().slice(0, 6),
      })),
    }),
    []
  );

  const searchData = data[searchType];
  const dataFiltered = searchData.filter((item) => {
    const title = get(item, 'title', '').toLowerCase();
    const path = get(item, 'path', '').toLowerCase();
    const code = get(item, 'code', '').toLowerCase();
    return title.includes(searchQuery) || path.includes(searchQuery) || code.includes(searchQuery);
  });

  const notFound = searchQuery && !dataFiltered.length;

  const renderItems = () => {
    return (
      <Stack
        px={2}
        pb={2}
        sx={{
          '& > *': {
            transition: 'all 0.2s ease-in-out',
            py: 1,
            px: 2,
            cursor: 'pointer',
            borderRadius: 1,
            userSelect: 'none',
            '&:hover': {
              bgcolor: (t) => alpha(t.palette.primary.main, 0.1),
            },
            '&:active': {
              transform: 'scale(0.98)',
            },
          },
        }}
      >
        {dataFiltered.map((item) => (
          <Stack
            direction="row"
            alignItems="center"
            key={get(item, 'title', '')}
            spacing={2}
            onClick={() => {
              router.push(get(item, 'path', ''));
              handleClose();
            }}
          >
            <Avatar />
            <Stack>
              <Typography variant="subtitle2" noWrap>
                {get(item, 'title', '')}
              </Typography>
              <Typography variant="caption" sx={{ color: 'text.secondary' }} noWrap>
                #{get(item, 'code', '')}
              </Typography>
            </Stack>
          </Stack>
        ))}
      </Stack>
    );
  };

  return (
    <>
      {renderButton}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={search.value}
        onClose={handleClose}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: 0,
        }}
        PaperProps={{
          sx: {
            mt: 15,
            overflow: 'unset',
          },
        }}
        sx={{
          [`& .${dialogClasses.container}`]: {
            alignItems: 'flex-start',
          },
        }}
      >
        {/* <Box sx={{ p: 3, borderBottom: `solid 1px ${theme.palette.divider}` }}>
          <InputBase
            fullWidth
            autoFocus
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            }
            endAdornment={<Label sx={{ letterSpacing: 1, color: 'text.secondary' }}>esc</Label>}
            inputProps={{
              sx: { typography: 'h6' },
            }}
          />
        </Box>

        <Scrollbar sx={{ p: 3, pt: 2, height: 400 }}>
          {notFound ? <SearchNotFound query={searchQuery} sx={{ py: 10 }} /> : renderItems()}
        </Scrollbar> */}

        <Stack
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%', p: 2 }}>
            <TextField
              select
              placeholder="Search in"
              size="medium"
              sx={{ width: 200 }}
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" width={24} sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                ),
              }}
            >
              <MenuItem value="students">Students</MenuItem>
              <MenuItem value="teachers">Teachers</MenuItem>
              <MenuItem value="classes">Classes</MenuItem>
            </TextField>
            <TextField
              placeholder="Search..."
              size="medium"
              fullWidth
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              autoFocus
            />
          </Stack>
          <Typography variant="subtitle2" sx={{ color: 'text.secondary', px: 2, pb: 2 }} noWrap>
            {dataFiltered.length} results
          </Typography>
          <Scrollbar sx={{ height: 400 }}>
            {notFound ? <SearchNotFound query={searchQuery} sx={{ py: 10 }} /> : renderItems()}
          </Scrollbar>
        </Stack>
      </Dialog>
    </>
  );
}

export default memo(Searchbar);
