import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const slice = createSlice({
  name: 'staffattendance',
  initialState: {
    schoolStructure: [
      {
        id: '33077998877733443358',
        name: '២០១៩-២០២០',
        name_en: '2019-2020',
      },
      {
        id: '39102703343428018028',
        name: '២០២០-២០២១',
        name_en: '2020-2021',
      },
      {
        id: '84731739371989380440',
        name: '២០២១-២០២២',
        name_en: '2021-2022',
      },
    ],
  },

  reducers: {},
});

export const StaffAttendanceSelector = () => useSelector((state) => state.staffattendance);
export const StaffAttendanceAction = slice.actions;
export default slice.reducer;
