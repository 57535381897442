import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

// @mui
import { LoadingButton } from '@mui/lab';
import { InputAdornment, Stack, TextField, IconButton, Alert } from '@mui/material';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';

// hooks
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// components
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import Iconify from 'src/components/iconify';
import { FormikProvider, useFormik } from 'formik';
import axiosInstance from 'src/utils/axios';
// -----------------------------------------------------

export default function ResetPasswordForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState(null);

  const formik = useFormik({
    initialValues: {
      current_password: '',
      current_password_show: false,
      password: '',
      new_password_show: false,
      password_confirmation: '',
    },
    validationSchema: Yup.object().shape({
      current_password: Yup.string().required('Current Password is required'),
      password: Yup.string().required('New Password is required'),
      password_confirmation: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Confirm Password must match with New Password'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const res = await axiosInstance.post('/change-password', {
          current_password: values.current_password,
          password: values.password,
          password_confirmation: values.password_confirmation,
        });
      } catch (e) {
        setErrorMsg(e.response.data.message || e.message);
      }
    },
  });

  return (
    <FormikProvider value={formik}>
      <Stack
        component="form"
        spacing={2.5}
        sx={{ width: { xs: '90%', md: '400px' } }}
        onSubmit={formik.handleSubmit}
      >
        {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <TextField
          fullWidth
          size="large"
          label="Current Password"
          type={formik.values.current_password_show ? 'text' : 'password'}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    formik.setFieldValue(
                      'current_password_show',
                      !formik.values.current_password_show
                    );
                  }}
                  edge="end"
                >
                  <Iconify
                    icon={
                      formik.values.current_password_show
                        ? 'solar:eye-bold'
                        : 'solar:eye-closed-bold'
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...formik.getFieldProps('current_password')}
          error={Boolean(formik.errors.current_password)}
          helperText={formik.errors.current_password}
        />

        <TextField
          fullWidth
          size="large"
          label="New Password"
          type={formik.values.new_password_show ? 'text' : 'password'}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    formik.setFieldValue('new_password_show', !formik.values.new_password_show);
                  }}
                  edge="end"
                >
                  <Iconify
                    icon={
                      formik.values.new_password_show ? 'solar:eye-bold' : 'solar:eye-closed-bold'
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...formik.getFieldProps('password')}
          error={Boolean(formik.errors.password)}
          helperText={formik.errors.password}
        />

        <TextField
          fullWidth
          size="large"
          label="Confirm Password"
          type={formik.values.new_password_show ? 'text' : 'password'}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    formik.setFieldValue('new_password_show', !formik.values.new_password_show);
                  }}
                  edge="end"
                >
                  <Iconify
                    icon={
                      formik.values.new_password_show ? 'solar:eye-bold' : 'solar:eye-closed-bold'
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...formik.getFieldProps('password_confirmation')}
          error={Boolean(formik.errors.password_confirmation)}
          helperText={formik.errors.password_confirmation}
        />

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          color="primary"
          variant="contained"
          // loading={isSubmitting}
          // onClick={() => {
          //   console.log(methods.formState.errors);
          // }}
        >
          {t('BUTTON.RESET')}
        </LoadingButton>
      </Stack>
    </FormikProvider>
  );
}
