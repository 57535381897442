import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  openDropoutStudentDialog: false,
  openListStudentFailedDialog: false,
  openListStudentPassedDialog: false,
  openReExamListDialog: false,
};

const slice = createSlice({
  name: 'student-status',
  initialState,
  reducers: {
    reset: () => initialState,
    setOpenDropoutStudentDialog: (state, action) => {
      state.openDropoutStudentDialog = action.payload;
    },
    setOpenListStudentFailedDialog: (state, action) => {
      state.openListStudentFailedDialog = action.payload;
    },
    setOpenListStudentPassedDialog: (state, action) => {
      state.openListStudentPassedDialog = action.payload;
    },
    setOpenReExamListDialog: (state, action) => {
      state.openReExamListDialog = action.payload;
    },
  },
});

export const studentStatusActions = slice.actions;
export const useStudentStatus = () => useSelector((state) => state.studentStatus);
export default slice.reducer;
