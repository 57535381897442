import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
import { paths } from '../paths';

// ===============================================================
const path = (p) => {
  const _path = p?.split('/');
  const _pathLength = _path?.length;
  const name = _path[_pathLength - 1] || '';
  return name;
};
// ===============================================================
const Page404 = lazy(() => import('src/pages/404'));
const ComingSoonPage = lazy(() => import('src/pages/ComingSoon'));
const TeacherPage = lazy(() => import('src/pages/dashboard/teacher'));

// ===============================================================

const DashboardPage = lazy(() => import('src/pages/dashboard'));

// ===================== Staff Information  =====================

const StaffInfoPage = lazy(() => import('src/pages/dashboard/staff-information'));

// ===================== Student Information (Status)  =====================

const LevelUp = lazy(() =>
  import('src/pages/dashboard/campus-setting/actions/student-status/LevelUp')
);
const ReExamList = lazy(() =>
  import('src/pages/dashboard/campus-setting/actions/student-status/ReExamList')
);
const ListStudentFail = lazy(() =>
  import('src/pages/dashboard/campus-setting/actions/student-status/ListStudentFail')
);
const ListStudentPass = lazy(() =>
  import('src/pages/dashboard/campus-setting/actions/student-status/ListStudentPass')
);
const DropoutStudent = lazy(() =>
  import('src/pages/dashboard/campus-setting/actions/student-status/DropoutStudent')
);

// ===================== Report & Statistic
const ReportStatisticStudent = lazy(() =>
  import('src/pages/dashboard/report-and-statistics/student/index')
);

// ===================== Campus Migration  =====================

const StaffMigration = lazy(() => import('src/pages/dashboard/campus-migration/staff-migration'));
const StudentMigration = lazy(() =>
  import('src/pages/dashboard/campus-migration/student-migration')
);
const StaffAttendance = lazy(() =>
  import('src/pages/dashboard/staff-information/staff-attendance')
);

// ----------------------------------------------------------------------

const StudentSearch = lazy(() => import('src/pages/dashboard/student-information/student-search'));
const GuardianInformation = lazy(() =>
  import('src/pages/dashboard/student-information/guardian-search')
);

// ----------------------------------------------------------------------

const SystemUserPage = lazy(() => import('src/pages/dashboard/system-user'));

// ======================= Campus Settings ==============================

const CampusSettingGrade = lazy(() => import('src/pages/dashboard/campus-setting/grade'));
const CampusSettingCalendar = lazy(() =>
  import('src/pages/dashboard/campus-setting/actions/multiple-schedule')
);

// ======================================================================
// General Settings
const EducationSystemPage = lazy(() =>
  import('src/pages/dashboard/general-settings/education-system')
);
const SubjectPage = lazy(() => import('src/pages/dashboard/general-settings/subject'));
const AssignmentPage = lazy(() => import('src/pages/dashboard/general-settings/assignment'));
const GradingSystemPage = lazy(() => import('src/pages/dashboard/general-settings/grading-system'));
const AcademicYearPage = lazy(() => import('src/pages/dashboard/general-settings/academic-year'));
const BranchPage = lazy(() => import('src/pages/dashboard/general-settings/branch'));
// ======================================================================

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <DashboardPage />, index: true },
      {
        path: path(paths.dashboard.campus_migration.root),
        children: [
          {
            path: path(paths.dashboard.campus_migration.student_migration),
            element: <StudentMigration />,
          },
          {
            path: path(paths.dashboard.campus_migration.staff_migration),
            element: <StaffMigration />,
          },
        ],
      },
      {
        path: path(paths.dashboard.student_information.root),
        children: [
          {
            path: path(paths.dashboard.student_information.search),
            element: <StudentSearch />,
          },
          {
            path: path(paths.dashboard.student_information.guardian),
            element: <GuardianInformation />,
          },
          {
            path: path(paths.dashboard.student_information.result),
            element: <ComingSoonPage />,
          },
        ],
      },
      {
        path: path(paths.dashboard.staff_information.root),
        children: [
          {
            path: path(paths.dashboard.staff_information.info),
            element: <StaffInfoPage />,
          },

          {
            path: path(paths.dashboard.staff_information.status),
            element: <ComingSoonPage />,
          },
          {
            path: path(paths.dashboard.staff_information.performance),
            element: <ComingSoonPage />,
          },
          {
            path: path(paths.dashboard.staff_information.attendance),
            element: <StaffAttendance />,
          },
        ],
      },
      {
        path: path(paths.dashboard.report_and_statistics.root),
        children: [
          {
            path: path(paths.dashboard.report_and_statistics.student.root),
            element: <ReportStatisticStudent />,
          },
          {
            path: path(paths.dashboard.report_and_statistics.staff.root),
            children: [
              {
                path: path(paths.dashboard.report_and_statistics.staff.info),
                element: <ComingSoonPage />,
              },
              {
                path: path(paths.dashboard.report_and_statistics.staff.status),
                element: <ComingSoonPage />,
              },
            ],
          },
        ],
      },
      {
        path: path(paths.dashboard.campus_setting.root),
        children: [
          {
            path: ':school',
            children: [
              {
                path: ':year',
                children: [
                  {
                    path: 'actions',
                    children: [
                      {
                        path: 'calendar',
                        element: <CampusSettingCalendar />,
                      },
                      {
                        path: 'student-status',
                        element: <LevelUp />,
                      },
                      // {
                      //   path: 'level-up',
                      //   element: <LevelUp />,
                      // },
                      // {
                      //   path: 're-exam',
                      //   element: <ReExamList />,
                      // },
                      // {
                      //   path: 'failed-students-list',
                      //   element: <ListStudentFail />,
                      // },
                      // {
                      //   path: 'passes-students-list',
                      //   element: <ListStudentPass />,
                      // },
                      // {
                      //   path: 'dropout-students-list',
                      //   element: <DropoutStudent />,
                      // },
                    ],
                  },
                  {
                    path: 'grades',
                    children: [
                      {
                        path: ':class/:class_type',
                        element: <CampusSettingGrade />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: path(paths.dashboard.general_setting.root),
        children: [
          {
            path: path(paths.dashboard.general_setting.education_system),
            element: <EducationSystemPage />,
          },
          {
            path: path(paths.dashboard.general_setting.subject.root),
            children: [
              {
                path: path(paths.dashboard.general_setting.subject.high_school),
                element: <SubjectPage />,
              },
              {
                path: path(paths.dashboard.general_setting.subject.secondary_school),
                element: <SubjectPage />,
              },
              {
                path: path(paths.dashboard.general_setting.subject.primary_school),
                element: <SubjectPage />,
              },
              {
                path: path(paths.dashboard.general_setting.subject.kindergarten),
                element: <SubjectPage />,
              },
            ],
          },
          {
            path: path(paths.dashboard.general_setting.assignment),
            element: <AssignmentPage />,
          },
          {
            path: path(paths.dashboard.general_setting.grading_system),
            element: <GradingSystemPage />,
          },
          {
            path: path(paths.dashboard.general_setting.academic_year.root),
            children: [
              {
                path: path(paths.dashboard.general_setting.academic_year.term),
                element: <AcademicYearPage />,
              },
            ],
          },
          {
            path: path(paths.dashboard.general_setting.branches),
            element: <BranchPage />,
          },
        ],
      },
      {
        path: path(paths.dashboard.system_user),
        element: <SystemUserPage />,
      },
      {
        path: 'teacher',
        element: <TeacherPage />,
      },

      { path: '*', element: <Page404 /> },
    ],
  },
];
