import React, { lazy } from 'react';

const ViewEvaluationDialog = lazy(() => import('src/components/dialogs/view-evaluation-dialog'));
const CreateEvaluationDialog = lazy(() =>
  import('src/components/dialogs/create-evaluation-dialog')
);
const TeacherHistoryDialog = lazy(() => import('src/components/dialogs/teacher-history-dialog'));

export default function LayoutContainer() {
  return (
    <>
      <ViewEvaluationDialog />
      <CreateEvaluationDialog />
      <TeacherHistoryDialog />
    </>
  );
}
