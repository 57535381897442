// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

const dashboard = (path) => `${ROOTS.DASHBOARD}${path}`;

export const paths = {
  // minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  register: {
    student: {
      register: '/student/register',
    },
  },
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
      reset: `${ROOTS.AUTH}/jwt/reset`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    campus_migration: {
      root: dashboard('/campus-migration'),
      student_migration: dashboard('/campus-migration/student-migration'),
      staff_migration: dashboard('/campus-migration/staff-migration'),
    },
    student_information: {
      root: dashboard('/student-information'),
      search: dashboard('/student-information/search'),
      guardian: dashboard('/student-information/guardian'),
      status: dashboard('/student-information/status'),
      result: dashboard('/student-information/result'),
    },
    staff_information: {
      root: dashboard('/staff-information'),
      info: dashboard('/staff-information/info'),
      status: dashboard('/staff-information/status'),
      performance: dashboard('/staff-information/performance'),
      attendance: dashboard('/staff-information/attendance'),
    },
    report_and_statistics: {
      root: dashboard('/report-and-statistics'),
      student: {
        root: dashboard('/report-and-statistics/student'),
        info: dashboard('/report-and-statistics/student/info'),
        status: dashboard('/report-and-statistics/student/status'),
      },
      staff: {
        root: dashboard('/report-and-statistics/staff'),
        info: dashboard('/report-and-statistics/staff/info'),
        status: dashboard('/report-and-statistics/staff/status'),
      },
    },
    campus_setting: {
      root: dashboard('/campus-setting'),
      high_school: {
        root: dashboard('/campus-setting/high-school'),
        calender: dashboard('/campus-setting/high-school/actions/calender'),
      },
      secondary_school: {
        root: dashboard('/campus-setting/secondary-school'),
        calender: dashboard('/campus-setting/secondary-school/actions/calender'),
      },
      primary_school: {
        root: dashboard('/campus-setting/primary-school'),
        calender: dashboard('/campus-setting/primary-school/actions/calender'),
      },
      kindergarten: {
        root: dashboard('/campus-setting/kindergarten'),
        calender: dashboard('/campus-setting/kindergarten/actions/calender'),
      },
    },
    general_setting: {
      root: dashboard('/general-setting'),
      education_system: dashboard('/general-setting/education-system'),
      subject: {
        root: dashboard('/general-setting/subject'),
        high_school: dashboard('/general-setting/subject/high-school'),
        secondary_school: dashboard('/general-setting/subject/secondary-school'),
        primary_school: dashboard('/general-setting/subject/primary-school'),
        kindergarten: dashboard('/general-setting/subject/kindergarten'),
      },
      assignment: dashboard('/general-setting/assignment'),
      grading_system: dashboard('/general-setting/grading-system'),
      academic_year: {
        root: dashboard('/general-setting/academic-year'),
        term: dashboard('/general-setting/academic-year/term'),
      },
      branches: dashboard('/general-setting/branches'),
    },
    system_user: dashboard('/system-user'),
    // teacher: dashboard('/teacher'),
    // staff: dashboard('/staff'),
    // student: dashboard('/student'),
  },
};
