import { combineReducers } from 'redux';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
// slices
import Logger from 'src/Logger';
import { REDUX_SECRET } from 'src/config-global';
// ----------------------------------------------------------------------

import TeacherReducer from './slices/teacher';
import StudentSearchReducer from './slices/studentSearch';
import staffMigration from './slices/staffMigration';
import studentMigration from './slices/studentMigration';
import multipleScheduleReducer from './slices/multiple-schedule';
import staffAttendance from './slices/staff-attendance';
import studentStatusReducer from './slices/student-status';
import staffInfo from './slices/staffInfo';

const logger = new Logger('Root Reducer');

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
  transforms: [
    encryptTransform({
      secretKey: REDUX_SECRET,
      onError(error) {
        // Handle the error.
        logger.error('rootPersistConfig transforms [error: %o]', error);
      },
    }),
  ],
};

const rootReducer = combineReducers({
  teacher: TeacherReducer,
  studentSearch: StudentSearchReducer,
  staffmigration: staffMigration,
  studentmigration: studentMigration,
  staffinfo: staffInfo,
  multipleSchedule: multipleScheduleReducer,
  studentStatus: studentStatusReducer,
});

export { rootPersistConfig, rootReducer };
