const kh = {
  TOOLTIP: {
    COMING_SOON: 'មកដល់ឆាប់ៗនេះ...',
  },

  SNACKBAR: {
    SAVED: 'បានរក្សាទុក',
    SUCCESSFUL: 'ជោគជ័យ',
    SAVING: 'កំពុងរក្សាទុក...',
    SURE_TO_DELETE: 'តើអ្នកប្រាកដថាចង់លុប',
    SURE_TO_LEAVE: 'តើអ្នកប្រាកដថាចង់ចេញពី',
    DUPLICATE_SUCCESSFULLY: 'បានចម្លងដោយជោគជ័យទៅ',
    START_BEFORE_END_DATE: 'ថ្ងៃបញ្ចប់ត្រូវតែនៅពីក្រោយថ្ងៃចាប់ផ្តើម',
    STATUS: 'ស្ថានភាព',
  },

  AUTOCOMPLETE: {
    ACADEMIC_YEAR: 'ឆ្នាំសិក្សា',
    EDUCATION_SYSTEM: 'ប្រព័ន្ធសិក្សា',
    GRADE: 'ថ្នាក់',
    CLASS: 'កម្រិត',
    FULLNAME_IN_LATIN: 'ឈ្មោះពេញជាឡាតាំង',
    FULLNAME_IN_KHMER: 'ឈ្មោះពេញជាខ្មែរ',
    STAFF_CODE: 'លេខកូដបុគ្គលិក',
    START_DATE: 'ថ្ងៃចាប់ផ្តើម',
    END_DATE: 'ថ្ងៃបញ្ចប់',
  },

  BUTTON: {
    SEND: 'ផ្ញើ',
    ALL: 'សរុប',
    SKIP: 'រំលង',
    ADD: 'បន្ថែម',
    LOGIN: 'ចូល',
    CLEAR: 'លុប',
    DRAFT: 'ព្រាង',
    LEAVE: 'ចេញ',
    DONE: 'រួចរាល់',
    NEXT: 'បន្ទាប់',
    BACK: 'ត្រឡប់',
    SAVE: 'រក្សាទុក',
    APPLY: 'បញ្ជូន',
    REVIEW: 'ពិនិត្យ',
    RETURN: 'ត្រឡប់',
    CONNECT: 'ភ្ជាប់',
    IMPORT: 'នាំចូល',
    CREATE: 'បង្កើត',
    EXPORT: 'នាំចេញ',
    CONFIRM: 'បញ្ជាក់',
    SHUFFLE: 'ក្រឡុក',
    CANCEL: 'បោះបង់',
    RENAME: 'ប្ដូរឈ្មោះ',
    TOPIC: 'ប្រធានបទ',
    DELETE: 'លុបចេញ',
    REMOVE: 'ដកចេញ',
    CONTINUE: 'បន្ទាប់',
    PUBLISH: 'បោះពុម្ព',
    MANAGE: 'គ្រប់គ្រង',
    SHARE: 'ចែករំលែក',
    DUPLICATE: 'ចម្លង',
    ANALYZING: 'វិភាគ',
    DISCARD: 'បោះបង់',
    PRESET: 'កំណត់មុន',
    ASSIGN: 'កំណត់ផ្ដល់',
    SETTING: 'ការកំណត់',
    DOWNLOAD: 'ទាញយក',
    ADD_TAG: 'បន្ថែមស្លាក',
    PREVIEW: 'មើលជាមុន',
    JOIN_CLASS: 'ចូលថ្នាក់',
    RESET: 'កំណត់ឡើងវិញ',
    SCHEDULE: 'កាលវិភាគ',
    EDIT: 'ធ្វើបច្ចុប្បន្នភាព',
    DISCONNECT: 'ផ្ដាច់ចេញ',
    GET_STARTED: 'ចាប់ផ្តើម',
    ASSIGN_TASK: 'ផ្ដល់កិច្ចការ',
    SUBMIT_QUIZ: 'ដាក់ស្នើសំណួរ',
    DELETE_COURSE: 'លុបមុខវិជ្ជា',
    PUBLISH_NOW: 'ផ្សព្វផ្សាយឥឡូវ',
    LEAVE_QUIZ: 'ចាកចេញពីសំណួរ',
    GO_TO_COURSE: 'ទៅកាន់មុខវិជ្ជា',
    LEAVE_COURSE: 'ចេញពីមុខវិជ្ជា',
    SAVE_DRAFT: 'រក្សាទុកសេចក្តីព្រាង',
    SAVE_CHANGE: 'រក្សាទុកការផ្លាស់ប្ដូរ',
    LEAVE_VIEW: 'ចាកចេញពីទិដ្ឋភាពនេះ',
    SEND_ADMIN: 'ផ្ញើទៅកាន់អ្នកគ្រប់គ្រង',
    ADD_DESCRIPTION: 'បន្ថែមការពិពណ៌នា',
    CONFIRM_CONTINUE: 'យល់ព្រម និង បន្ត',
    PLAY_RECORDING: 'លេងដែលបានថតទុក',
    REVERT_TO_DRAFT: 'ត្រឡប់ទៅកន្លែងព្រាងវិញ',
    UPDATE_PRESET: 'ធ្វើបច្ចុប្បន្នភាពកំណត់ជាមុន',
    UPDATE_ATTENDANCE: 'ធ្វើបច្ចុប្បន្នភាពវត្តមាន',
    START_WITH_ADD: 'ចាប់ផ្តើមថ្មីដោយប្រើប៊ូតុងបង្កើត',
    SEARCH: 'ស្វែងរក',
    DOWNLOAD_EXCEL_TEMPALTE: 'ទាញយក Excel គំរូ',
    IMPORT_EXCEL_FILE: 'នាំចូល Excel',
    UPLOAD: 'ផ្ទុកឡើង',
  },

  LOGIN_PAGE: {
    PASSWORD: 'ពាក្យសម្ងាត់',
    BACKGROUND: 'ផ្ទៃក្រោយ',
    REMEMBER_SCHOOL: 'ចង់ចាំសាលា',
    SELECT_SCHOOL: 'ជ្រើសរើសសាលា',
    FORGOT_PASSWORD: 'ភ្លេចលេខសម្ងាត់?',
    USERNAME: 'ឈ្មោះ​អ្នកប្រើប្រាស់​ ឬ ​អ៊ីមែ​ល',
    YOUR_SCHOOL: 'សូមមេត្តាជ្រើសរើសសាលារបស់អ្នក',
    LOGIN_TO_ACCESS: 'ចូលដើម្បីប្រើប្រព័ន្ធគ្រប់គ្រង CAMIS',
  },

  STUDENT_INFO_STATUS: {
    STUDENT_INFO: 'ព័ត៌មាននិស្សិត',
    STUDENT_STATUS: 'ស្ថានភាពសិស្ស',
    LEVEL_UP_TAB: 'ឡើងថ្នាក់ ឬ ត្រួតថ្នាក់',
    DROPOUT_STUDENT_TAB: 'សិស្សបោះបង់ការសិក្សា',
    LIST_STUDENT_FAIL_TAB: 'តារាងសិស្សត្រួតថ្នាក់',
    LIST_STUDENT_PASS_TAB: 'តារាងសិស្សឡើងថ្នាក់',
    RE_EXAM_LIST_TAB: 'បញ្ជីឈ្មោះសិស្សប្រឡងឡើងថ្នាក់',
  },

  SYSTEM_USER: {
    GENERAL_TAB: 'ទូទៅ',
    USER_SETTING: 'កំណត់​គណនី',
    SYSTEM_USER: 'អ្នកប្រើប្រាស់ប្រព័ន្ធ',
    INFORMATION_TAB: 'ព័ត៌មានទាក់ទង',
    CHANGE_PASSWORD_TAB: 'ផ្លាស់ប្ដូរពាក្យសម្ងាត់',

    GENERAL: {
      GENDER: 'ភេទ',
      ALLOWED: 'អនុញ្ញាត',
      RELIGION: 'សាសនា',
      NAME: 'ឈ្មោះហៅក្រៅ',
      LASTNAME: 'នាមខ្លួន',
      DOB: 'ថ្ងៃខែឆ្នាំកំណើត',
      NATONALITY: 'សញ្ជាតិ',
      ETHNICITY: 'ជនជាតិ',
      UPDATE_PHOTO: 'ប្ដូររូប',
      FIRSTNAME: 'នាមត្រកូល',
      MAX_SIZE: 'ទំហំអតិបរមា',
      UPLOAD_PHOTO: 'បង្ហោះរូប',
      REID_ID: 'លេខសម្គាស់កាត',
      OWN_CODE: 'អត្តលេខបុគ្គលិក',
      CAMEMIS_CODE: 'អត្តលេខ CAMEMIS',
      LASTNAME_LATIN: 'នាមខ្លួន (ឡាតាំង)',
      FIRSTNAME_LATIN: 'នាមត្រកូល (ឡាតាំង)',
    },

    INFORMATION: {
      ADDRESS: 'អាស័យដ្ឋាន',
      CONTACT_EMAIL: 'អ៊ីមែលទាក់ទង',
      CONTACT_PHONE_NUMBER: 'ទូរស័ព្ទទាក់ទង',
    },

    CHANGE_PASSWORD: {
      PASSWORD: 'ពាក្យសម្ងាត់',
      CURRENT_PASSWORD: 'ពាក្យសម្ងាត់បច្ចុប្បន្ន',
      PASSWORD_COMFIRMATION: 'ពាក្យសម្ងាត់ផ្ទៀងផ្ទាត់',
    },
  },
  REGISTER_NEW_STUDENT: {
    LEVEL: 'សូមជ្រើសរើសកម្រិតថ្នាក់',
    CONFIRM_LEVEL: 'សូមមេត្តាជ្រើសរើសកម្រិតថ្នាក់',
  },
};

export default kh;
