export function generateStructure(school_type, path, academic_years, grades, classes) {
  return {
    title: school_type,
    path,
    children: academic_years.map((year) => ({
      title: year,
      path: `${path}/${year}`,
      children: [
        {
          title: 'Actions',
          path: `${path}/${year}/actions`,
          children: [
            {
              title: 'Multiple Schedule',
              path: `${path}/${year}/actions/calendar`,
            },
            {
              title: 'Student Status',
              path: `${path}/${year}/actions/student-status`,
            },
            // {
            //   title: 'Level Up',
            //   path: `${path}/${year}/actions/level-up`,
            // },
            // {
            //   title: 'Re-Exam',
            //   path: `${path}/${year}/actions/re-exam`,
            // },
            // {
            //   title: 'Failed Students List',
            //   path: `${path}/${year}/actions/failed-students-list`,
            // },
            // {
            //   title: 'Passed Students List',
            //   path: `${path}/${year}/actions/passes-students-list`,
            // },
            // {
            //   title: 'Dropout Students List',
            //   path: `${path}/${year}/actions/dropout-students-list`,
            // },
          ],
        },
        {
          title: 'Grades',
          path: `${path}/${year}/grades`,
          children: grades.map((grade) => ({
            title: `Grade ${grade}`,
            path: `${path}/${year}/grades/${grade}`,
            children: classes
              ? classes.map((cls) => ({
                  title: `Class ${grade}${cls}`,
                  path: `${path}/${year}/grades/${grade}/${cls}`,
                }))
              : undefined,
          })),
        },
      ],
    })),
  };
}
