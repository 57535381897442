import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { io } from 'socket.io-client';

const context = createContext({
  access_modules: [],
  socket: io(),
});

export const useAccessModule = () => useContext(context);

export const AccessModuleProvider = ({ children }) => {
  const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
    extraHeaders: {
      Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      userid: sessionStorage.getItem('userId'),
    },
    autoConnect: false,
    reconnection: true,
  });
  const [access_modules, setAccessModules] = useState(null);

  const initWs = () => {
    socket.connect();

    socket.on('connect', () => {
      console.log('connect');
    });

    socket.on('access_modules', (data) => {
      setAccessModules(data);
      console.log('access_modules', data);
    });

    socket.on('disconnect', (reason) => {
      console.log('disconnect', reason);
    });
  };

  useEffect(() => {
    initWs();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo(
    () => ({ access_modules, setAccessModules, socket }),
    [access_modules, socket]
  );

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};
