import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import { REDUX_LOGGER } from 'src/config-global';
import { rootPersistConfig, rootReducer } from './rootReducer';

// ----------------------------------------------------------------------

const isProduction = process.env.NODE_ENV === 'production';
const middlewares = [];

if (REDUX_LOGGER) {
  const LOG_IGNORE = [];
  const logger = createLogger({
    // filter VOLUME level actions from log
    predicate: (getState, action) => LOG_IGNORE.indexOf(action.type) === -1,
    duration: true,
    timestamp: false,
    level: 'log',
    logErrors: true,
  });

  middlewares.push(logger);
}

const store = configureStore({
  devTools: !isProduction,
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(middlewares),
});

const persistor = persistStore(store);

const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();

export { dispatch, persistor, store, useDispatch, useSelector };
