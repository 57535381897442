/* eslint-disable */
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';

import Box from '@mui/material/Box';
import { useRef } from 'react';
import { useSettingsContext } from 'src/components/settings';
import { AccessModuleProvider } from 'src/hooks/use-access-module';
import { useBoolean } from 'src/hooks/use-boolean';
import { usePersistState } from 'src/hooks/use-persist-state';
import { useResponsive } from 'src/hooks/use-responsive';
import LockScreen from '../lockscreen';
import Header from './header';
import LayoutContainer from './layout-container';
import Main from './main';
import NavHorizontal from './nav-horizontal';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const IdleTimerRef = useRef(null);
  const [locked, setLocked] = usePersistState('locked', false);
  const settings = useSettingsContext();
  const lgUp = useResponsive('up', 'lg');
  const TIMEOUT_MINUTE = 10;
  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  const onIdle = () => {
    setLocked(true);
  };

  if (locked) {
    return <LockScreen lockState={[locked, setLocked]} />;
  }

  if (isHorizontal) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main>{children}</Main>
      </>
    );
  }

  if (isMini) {
    return (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main>{children}</Main>
        </Box>
      </>
    );
  }

  return (
    <AccessModuleProvider>
      <Header onOpenNav={nav.onTrue} />
      <IdleTimer ref={IdleTimerRef} timeout={1000 * 60 * TIMEOUT_MINUTE} onIdle={onIdle} />
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {renderNavVertical}
        <LayoutContainer />
        <Main>{children}</Main>
      </Box>
    </AccessModuleProvider>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
