import { Container, Stack, TextField, Typography, Button } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SeoIllustration } from 'src/assets/illustrations';
import { useMockedUser } from 'src/hooks/use-mocked-user';

export default function LockScreen({ lockState }) {
  const [locked, setLocked] = lockState;
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const { t } = useTranslation();
  const { user } = useMockedUser();

  const onUnlock = () => {
    if (password === '123456') {
      setLocked(false);
    } else {
      setError('Incorrect password');
    }
  };

  return (
    <Container maxWidth="xl" sx={{ height: '100%' }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          height: '100%',
        }}
      >
        <Stack spacing={2} direction="column" alignItems="center" justifyContent="center">
          <SeoIllustration
            sx={{
              p: 3,
              width: 400,
              margin: { xs: 'auto', md: 'inherit' },
            }}
          />

          <Typography textAlign="center" paragraph variant="h4">
            Welcome Back, {user?.displayName}
          </Typography>

          <Typography textAlign="center" variant="body1">
            Due to security regulations, your system has been temporarily shut down. Please sign in
            again...
          </Typography>

          <Typography variant="body2" color="text.secondary">
            Please, enter your user password to continue your work. ( In testing mood password:
            123456 )
          </Typography>

          <TextField
            label={t('LOGIN_PAGE.PASSWORD')}
            type="password"
            sx={{ width: 280, mt: 2 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={Boolean(error)}
            helperText={error}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onUnlock();
              }
            }}
            autoComplete="off"
            name="lock-password"
            defaultValue=""
          />
          <Button
            variant="contained"
            size="medium"
            sx={{ px: 4, py: 2 }}
            onClick={onUnlock}
            disabled={password === ''}
          >
            {t('BUTTON.LOGIN')}
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
