import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  openMapTeacherDialog: false,
  openInstructorDialog: false,
  openTimetableSettingsDialog: false,
  openNumberOfSession: false,
};

const slice = createSlice({
  name: 'multiple-schedule',
  initialState,
  reducers: {
    reset: () => initialState,
    setOpenMapTeacherDialog: (state, action) => {
      state.openMapTeacherDialog = action.payload;
    },
    setOpenInstructorDialog: (state, action) => {
      state.openInstructorDialog = action.payload;
    },
    setOpenTimetableSettingsDialog: (state, action) => {
      state.openTimetableSettingsDialog = action.payload;
    },
    setOpenNumberOfSession: (state, action) => {
      state.openNumberOfSession = action.payload;
    },
  },
});

export const multipleScheduleActions = slice.actions;
export const useMultipleSchedule = () => useSelector((state) => state.multipleSchedule);
export default slice.reducer;
